import React, { Component } from "react"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import styled from "styled-components"
import PhotographyItem from "../components/photography-item"
import FsLightbox from "fslightbox-react"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Grid = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`

const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30vh;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 850px) {
    justify-content: flex-start;
  }
`
const Tag = styled.button`
  margin: 0 10px;
  font-size: 11px;
  background: none;
  border: none;
  color: white;
  font-family: "Lato";
  cursor: pointer;
  font-weight: ${props => props.active && 900};

  :focus {
    outline: none;
  }
`
const Container = styled.div`
  flex-basis: 16.6%;
  height: 200px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /*Media Queries*/
  @media (max-width: 1000px) {
    flex-basis: 25%;
  }

  @media (max-width: 850px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 600px) {
    flex-basis: 50%;
  }
`
const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-out;
  transform: scale(1);

  ${Container}:hover & {
    transform: scale(1.1);
  }
`
const ImgWrapper = styled.div`
  height: 100%;
  transition: 0.3s ease-out;
  transform: scale(1);

  ${Container}:hover & {
    transform: scale(1.1);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease-out;
    transform: scale(1);
  }
`

class PhotographyPage extends Component {
  state = {
    loadingGallery: true,
    activeGallery: null,
    isOpen: true,
    photoIndex: 0,
    imageUrls: null,
  }
  componentDidMount() {
    //Set the gallery to be the first title returned
    const photographyData = this.props.data.allWordpressPost.edges
    console.log(photographyData)
    this.setState({
      activeGallery: photographyData[0].node.acf.gallery,
      activeTitle: photographyData[0].node.title,
      imageUrls: photographyData[0].node.acf.gallery.map(
        object => object.source_url
      ),
      loadingGallery: false,
    })
  }
  handleTagSelect = index => {
    const photographyData = this.props.data.allWordpressPost.edges
    this.setState({ loadingGallery: true })
    this.setState({
      activeGallery: photographyData[index].node.acf.gallery,
      activeTitle: photographyData[index].node.title,

      imageUrls: photographyData[index].node.acf.gallery.map(
        object => object.source_url
      ),
      loadingGallery: false,
    })
  }

  openLightbox = i => {
    this.setState({ isOpen: !this.state.isOpen, photoIndex: i })
  }

  render() {
    const photographyData = this.props.data.allWordpressPost.edges
    const {
      isOpen,
      activeGallery,
      activeTitle,
      photoIndex,
      imageUrls,
      loadingGallery,
    } = this.state

    return (
      <Layout>
        <SEO title="Photography" />
        <PageHeader>Photography</PageHeader>
        <TagsContainer>
          {photographyData.map((photos, index) => (
            <Tag
              active={photos.node.title === activeTitle}
              onClick={() => this.handleTagSelect(index)}
              key={index}
            >
              {photos.node.title}
            </Tag>
          ))}
        </TagsContainer>
        <Grid>
          {activeGallery &&
            !this.state.loadingGallery &&
            activeGallery.map((photo, index) => (
              <Container key={index} onClick={() => this.openLightbox(index)}>
                {/* <Cover src={photo.source_url} /> */}
                <ImgWrapper>
                  <Img
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: 0,
                      objectFit: "cover",
                      transform: "scale(1)",
                      ":hover": {
                        transform: "scale(1.)",
                      },
                    }}
                    fadeIn
                    objectFit="cover"
                    fixed={photo.localFile.childImageSharp.fixed}
                  />
                </ImgWrapper>
              </Container>
            ))}
        </Grid>
        {imageUrls && (
          <FsLightbox
            key={imageUrls}
            toggler={isOpen}
            slide={photoIndex + 1}
            sources={imageUrls}
            type="image"
          />
        )}
      </Layout>
    )
  }
}

export default PhotographyPage

export const photographyQuery = graphql`
  query($category: String = "Photography") {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          acf {
            gallery {
              source_url
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
